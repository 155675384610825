import { Route } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['../assets/css/style.css'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {
	title = 'Welcome To : Clinics On Cloud';

	constructor() { }

	ngOnInit() {
	}

	printpath(parent: String, config: Route[]) {
		for (let i = 0; i < config.length; i++) {
			const route = config[i];

			if (route.children) {
				const currentPath = route.path ? parent + '/' + route.path : parent;
				this.printpath(currentPath, route.children);
			}
		}
	}
}
