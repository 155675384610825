<section class="doctor-profile" *ngIf="doctor">
    <div class="doctor-profile-cover">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-12">
                    <div class="d-flex">
                        <div class="doctor-profile-image">
                            <img src="{{doctorImage}}" class="img-responsive" />
                        </div>
                        <div class="doctor-profile-info">
                            <h1 class="doctor-name">{{doctorName}}({{doctor.gender}})</h1>
                            <p class="doctor-designation" *ngIf="doctorEducationsFound">{{doctorEducations}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="doctor-profile-details">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="doctor-profile-details-block" *ngIf="false">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-user" aria-hidden="true"></i> About</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer
                                took a
                                galley of type and scrambled it to make a type specimen book. It has survived not only
                                five
                                centuries, but also the leap into electronic typesetting, remaining essentially
                                unchanged.
                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                                Ipsum
                                passages, and more recently with desktop publishing software like Aldus PageMaker
                                including
                                versions of Lorem Ipsum.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="doctor-profile-details-block">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-star" aria-hidden="true"></i> Hospital Associations</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1" *ngIf="doctorHospitalAssociationsFound">
                                <li *ngFor="let association of doctor.doctorHospitalAssociations; let i=index">
                                    {{association.hospital}}</li>
                            </ul>
                            <span class='no-data-found' *ngIf="!doctorHospitalAssociationsFound">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="doctor-profile-details-block">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-trophy" aria-hidden="true"></i> Achievements</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1" *ngIf="doctorAchievementsFound">
                                <li *ngFor="let achievement of doctor.doctorAchievements; let i=index">
                                    {{achievement.title}}
                                </li>
                            </ul>
                            <span class='no-data-found' *ngIf="!doctorAchievementsFound">-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="doctor-profile-details-block">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-heartbeat" aria-hidden="true"></i> Services</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1" *ngIf="doctorServicesFound">
                                <li *ngFor="let service of doctor.doctorServices; let i=index"> {{service.title}}</li>
                            </ul>
                            <span class='no-data-found' *ngIf="!doctorServicesFound">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="doctor-profile-details-block">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-sticky-note" aria-hidden="true"></i> Specialization</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1" *ngIf="doctorEducationsFound">
                                <li *ngFor="let specialization of doctor.doctorSpecializations; let i=index">
                                    {{specialization.title}}</li>
                            </ul>
                            <span class='no-data-found' *ngIf="!doctorSpecializationsFound">-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="doctor-profile-details-block" *ngIf="false">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-graduation-cap" aria-hidden="true"></i> Qualification</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1" *ngIf="doctorEducationsFound">
                                <li *ngFor="let qualification of doctor.doctorEducations; let i=index">
                                    {{qualification.title}}</li>
                            </ul>
                            <span class='no-data-found' *ngIf="!doctorEducationsFound">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="false">
                    <div class="doctor-profile-details-block">
                        <div class="doctor-profile-details-title">
                            <h4><i class="fa fa-users" aria-hidden="true"></i> Memberships</h4>
                        </div>
                        <div class="doctor-profile-details-info">
                            <ul type="1">
                                <li>Life Member, Indian Associations of Dermologists</li>
                                <li>Member Cosmetic Society of Inida</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>