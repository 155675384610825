import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConstantsService {
    REQUEST_METHOD_GET: string = 'GET';
    REQUEST_METHOD_PUT: string = 'PUT';
    REQUEST_METHOD_POST: string = 'POST';
    REQUEST_METHOD_DELETE: string = 'DELETE';

    ASSET_BASE_PATH = environment.ASSET_BASE_PATH;

    SERVER_ROOT_URL: string = environment.SERVER_ROOT_URL;

    API_VERSION_V1: string = "api/v1/";
    API_VERSION_V2: string = "api/v2/";
    API_VERSION_V3: string = "api/v3/";

    /******************* API URLS *********************/

    API_URL_CDN: string = 'cdn/';
    API_URL_ECG: string = 'ecg/';
    API_URL_CHART: string = 'chart';
    API_URL_COUNT: string = 'count';
    API_URL_COUNTRY: string = 'country/';
    API_URL_CHECK_LOGIN: string = 'login/dashboard';
    API_URL_DASHBOARD_AGGREGATE: string = 'dashboard-aggregate';

    API_URL_CLINIC: string = 'clinic/';
    API_URL_CLINIC_TYPE: string = 'clinic-type/';
    API_URL_CLINIC_IMAGE: string = 'clinic-image/';
    API_URL_CLINIC_USER: string = 'user-clinic/';
    API_URL_CLINIC_DOCTOR: string = 'doctor-clinic/';
    API_URL_COLOR_BLINDNESS: string = 'color-blindness/';
    API_URL_CLINIC_TEST_TYPE: string = 'clinic-test-type/';

    API_URL_CONFIG: string = 'config/';
    API_URL_CUSTOMER: string = 'customer/';
    API_URL_COVID_SYMPTOM: string = 'covid-symptom/';

    API_URL_DISTRICT: string = 'district/';
    API_URL_DOCUMENT: string = 'document/';
    API_URL_DASHBOARD: string = 'dashboard/';
    API_URL_DIAGNOSIS: string = 'diagnosis/';
    API_URL_DERMASCOPE: string = 'dermascope/';
    API_URL_DOCTOR_SERVICE: string = 'doctor-service/';
    API_URL_DOCTOR_LANGUAGE: string = 'doctor-language/';
    API_URL_DOCTOR_EXPERTISE: string = 'doctor-expertise/';
    API_URL_TEST_DEPENDENCY: string = 'test-dependency/';
    API_URL_DEVICE_MANUFACTURER: string = 'device-manufacturer/';
    API_URL_DOCTOR_SPECIALIZATION: string = 'doctor-specialization/';

    API_URL_HBA1C_TEST: string = 'hba1c-test/';
    API_URL_EXTERNAL_INTEGRATION: string = 'external-integration/';

    API_URL_ITEM: string = 'item/';
    API_URL_ITEM_CATEGORY: string = 'item-category/';

    API_URL_KIOSK_ITEM: string = 'kiosk-item/';
    API_URL_KIOSK_CONFIGURATION: string = 'kiosk-configuration/';
    API_URL_KIOSK_CONFIGURATION_TEST_CATEGORY: string = 'kiosk-configuration-test-category/';

    API_URL_LAB_TEST: string = 'lab-test/';
    API_URL_LETTER_HEAD_IMAGE: string = 'letter-head-image/';

    API_URL_LOG: string = 'log/';
    API_URL_LOGOUT: string = 'logout';
    API_URL_LOCATION: string = 'location/';
    API_URL_LIPID_TEST: string = 'lipid-test/';
    API_URL_LETTER_HEAD_TYPE: string = 'letter-head-type/';
    API_URL_LIPID_HBA1C_TEST: string = 'lipid-hba1c-test/';

    API_URL_MEDICINE: string = 'medicine/';
    API_URL_MEDICAL_COMPLAINT: string = 'medical-complaint/';
    API_URL_MENTAL_ASSESSMENT: string = 'mental-assessment/';
    API_URL_MEDICAL_COMPLICATION: string = 'medical-complication/';
    API_URL_TUBERCULOSIS: string = 'tuberculosis/';
    API_URL_AYURVEDIC: string = 'ayurvedic/';


    API_URL_NEWS: string = 'news/';
    API_URL_NEWS_CATEGORY: string = 'news-category/';
    API_URL_NEWS_ATTACHMENT: string = 'news-attachment/';
    API_URL_NOTIFICATION_CONFIG: string = 'notification-config/';

    API_URL_OTOSCOPE: string = 'otoscope/';
    API_URL_OBSERVATION: string = 'observation/';

    API_URL_PARAMETER: string = 'parameter/';

    API_URL_PATIENT: string = 'patient/';
    API_URL_PATIENT_IMAGE: string = 'patient-image/';
    API_URL_PATIENT_UPLOAD: string = 'patient-upload/';
    API_URL_PATIENT_REMOVE: string = 'patient/remove/';
    API_URL_PATIENT_PASSWORD: string = 'patient/password/';
    API_URL_PATIENT_COVID_RESULT: string = 'patient-covid-result/';
    API_URL_PATIENT_QUESTION_RESPONSE: string = 'patient-question-response/';

    API_URL_PERMISSION: string = 'permission/';

    API_URL_QUESTION: string = 'question/';
    API_URL_QUESTION_CATEGORY: string = 'question-category/';

    API_URL_ROLE: string = 'role/';
    API_URL_RAPID_TEST: string = 'rapid-test/';

    API_URL_STATE: string = 'state/';
    API_URL_STOCK: string = 'stock/';
    API_URL_SETTING: string = 'setting/';
    API_URL_SUMMARY: string = 'summary/';
    API_URL_SPIROMETER: string = 'spirometer/';
    API_URL_COUGH: string = 'cough-test/';
    API_URL_STETHOSCOPE: string = 'stethoscope/';
    API_URL_STOCK_CATEGORY: string = 'stock-category/';

    API_URL_TEST: string = 'test/';
    API_URL_TEST_TYPE: string = 'test-type/';
    API_URL_TREATMENT: string = 'treatment/';
    API_URL_TELEMEDICINE: string = 'telemedicine/';
    API_URL_TEST_CATEGORY: string = 'test-category/';
    API_URL_TEST_CATEGORY_TEST: string = 'test-category-test/';
    API_URL_TEST_DEVICE_MANUFACTURER: string = 'test-device-manufacturer/';

    API_URL_USER: string = 'user/';
    API_URL_UPDATE_ALL: string = 'update-all';
    API_URL_URINE_TEST: string = 'urine-test/';
    API_URL_USER_IMAGE: string = 'user-image/';
    API_URL_USER_PASSWORD: string = 'user/password/';

    API_URL_TICKET: string = 'ticket/';
    API_URL_TICKET_TYPE: string = 'ticket-type/';
    API_URL_TICKET_THREAD: string = 'ticket-thread/';
    API_URL_TICKET_UNREAD_COUNT: string = 'ticket/unread/count';

    API_URL_DOCTOR: string = 'doctor/';
    API_URL_DOCTOR_EDUCATION: string = 'doctor-education/';
    API_URL_DOCTOR_ACHIEVEMENT: string = 'doctor-achievement/';
    API_URL_DOCTOR_CONSULTATION: string = 'doctor-consultation/';
    API_URL_DOCTOR_HOSPITAL_ASSOCIATION: string = 'doctor-hospital-association/';

    API_URL_MEDCINE: string = 'medicine/';

    API_URL_LETTERHEAD: string = 'letter-head/';

    API_URL_APK: string = 'apk/';

    API_URL_VIDEO_CATEGORY: string = 'video-category/';
    API_URL_VIDEO: string = 'video/';


    API_URL_KIOSK_LOG: string = 'kiosk-log/';
    API_URL_SECRET_KEY: string = 'user-api-key/';

    API_CUSTOMER_TEST_MAPPING: string = 'customer-test-mapping/'
    API_CUSTOMER_DEVICE_MAPPING: string = 'customer-device-mapping/'

    API_CLINIC_TEST_MAPPING: string = 'clinic-test-mapping/'
    API_CLINIC_DEVICE_MAPPING: string = 'clinic-device-mapping/'

    CDN_ATTACHMENTS_NEWS: string = 'attachments/news/';
    CDN_ATTACHMENTS_TICKET: string = 'attachments/ticket/';

    CDN_IMAGES_USER: string = 'images/user/';
    CDN_IMAGES_PATIENT: string = 'images/patient/';

    CDN_DOCUMENTS_USER: string = 'documents/user/';
    CDN_DOCUMENTS_PATIENT: string = 'documents/patient/';

    DEFAULTS_USER_PICTURE = 'assets/images/default/user.jpg';
    DEFAULTS_PATIENT_PICTURE = 'assets/images/default/patient.jpg';

    ENTITY_USER: string = 'USER';
    ENTITY_PATIENT: string = 'PATIENT';
    ENTITY_SUPER_USER: string = 'SUPERUSER';

    GENDER_MALE: string = 'Male';
    GENDER_FEMALE: string = 'Female';

    OPERATION_TYPE_CREATE: string = 'CREATE';
    OPERATION_TYPE_UPDATE: string = 'UPDATE';

    RESPONSE_CODE_ERROR: number = 1;
    RESPONSE_CODE_NOT_FOUND: number = 2;
    RESPONSE_CODE_READ_ERROR: number = 3;
    RESPONSE_CODE_CREATE_ERROR: number = 4;
    RESPONSE_CODE_UPDATE_ERROR: number = 5;
    RESPONSE_CODE_DELETE_ERROR: number = 6;
    RESPONSE_CODE_READ_SUCCESS: number = 7;
    RESPONSE_CODE_CREATE_SUCCESS: number = 8;
    RESPONSE_CODE_UPDATE_SUCCESS: number = 9;
    RESPONSE_CODE_DELETE_SUCCESS: number = 10;
    RESPONSE_CODE_EMAIL_SENT: number = 11;
    RESPONSE_CODE_EMAIL_SENDING_FAILED: number = 12;
    RESPONSE_CODE_DUPLICATE_RECORD: number = 13;

    RESPONSE_CODE_VALID_LOGIN: number = 14;
    RESPONSE_CODE_INVALID_LOGIN: number = 15;
    RESPONSE_CODE_FILE_UPLOADED: number = 16;
    RESPONSE_CODE_DUPLICATE_EMAIL: number = 17;

    RESPONSE_CODE_HTTP_STATUS_OK: number = 200;
    RESPONSE_CODE_HTTP_STATUS_ERROR: number = 500;
    RESPONSE_CODE_HTTP_STATUS_FORBIDDEN: number = 403;
    RESPONSE_CODE_HTTP_STATUS_NOT_FOUND: number = 404;

    ROLE_USER: string = 'USER';
    ROLE_CASE_MANAGER: string = 'CASE MANAGER';
    ROLE_ADMINISTRATOR: string = 'ADMINISTRATOR';
    ROLE_SUPER_ADMINISTRATOR: string = 'SUPER ADMINISTRATOR';

    MESSAGE_TIMEOUT: number = 3000;
    CHAT_RELOAD_INTERVAL: number = 10000;
    ONLINE_DOCTORS_RELOAD_INTERVAL: number = 30000;
    PATIENT_CALL_MISSED_WAIT_TIMEOUT: number = 15000;

    MAX_FILE_UPLOAD_SIZE: number = 10000;
    MAX_FILE_UPLOAD_SIZE_MB_TEXT: string = '10 mb';

    DOCUMENT_TYPE_ID_NAME: string = "ID";

    PAGE_TYPE_CREATE = 1;
    PAGE_TYPE_EDIT = 2;
    PAGE_TYPE_LIST = 3;
    PAGE_TYPE_VIEW = 4;
    PAGE_TYPE_PROFILE = 5;

    PROFILE_PICTURE_PATIENT = 1;
    PROFILE_PICTURE_DOCTOR = 2;
    PROFILE_PICTURE_USER = 3;
    PROFILE_PICTURE_REFERRAL_PARTNER = 4;

    TITLE_MALE: string = 'Mr.';
    TITLE_FEMALE: string = 'Ms.';

    MESSAGE_CREATED: string = 'Record created successfully';
    MESSAGE_STOCK_FORM_INVALID: string = 'Please complete the stock form';

    CHART_BACKGROUND_COLOR: string = '#757070';
    CHART_BACKGROUND_COLORS: string[] = ['#757070', '#a07058', '#929cd0', '#808000', '#40E0D0', '#008B8B', '#4682B4', '#DDA0DD', '#FAEBD7', '#778899', '#CD853F'];

    DEFAULT_CLINIC_LOGO: string = 'coc.png';

    INSTALLATION_STEP_INSTALLED: string = 'Installed';
    INSTALLATION_STEP_PIPELINE: string = 'Pipeline';
    INSTALLATION_STEP_TRANSPORT: string = 'Transport';

    USER_TYPE_USER: string = 'User';
    USER_TYPE_DOCTOR: string = 'Doctor';
    USER_TYPE_CUSTOMER: string = 'Customer';
    USER_TYPE_ADMINISTRATOR: string = 'Administrator';

    VIDEO_CHAT_ROOM_PREFIX: string = 'cocvcr_';    // coc video chat room prefix

    AUDIO_PATIENT_CALLING: string = "patient-calling.mp3";

    COLORS = {
        LOW: '#3ca3c2',
        HIGH: '#ffa500',
        MALE: '#3ca3c2',
        NORMAL: '#9bbfc2',
        FEMALE: '#f8b9d4',
        DEFAULT: '#716f6e',
        NEGATIVE: '#adadad',
        POSITIVE: '#9d9d9d',
        STANDARD: '#26ca76',
        OPTIMAL: '#16cb36',
        ADEQUATE: '#26ca76',
        DIABETIC: '#ef3636',
        DESIRABLE: '#26ca76',
        VERY_HIGH: '#c76565',
        SYMPTOMATIC: '#adadad',
        BORDER_HIGH: '#deaf64',
        BORDER_LINE: '#deaf64',
        PREDIABETIC: '#ffa500',
        NEAR_OPTIMAL: '#95a946',
        ASYMPTOMATIC: '#9d9d9d',
        SERIOUSLY_LOW: '#5c51ff',
        SERIOUSLY_HIGH: '#ef3636',
        BORDER_LINE_HIGH: '#de8f64',
        NOT_UP_TO_STANDARD: '#ffa500'
    };

    MODULES = {
        EMR: 'Emr',
        APK: 'Apk',
        LOGS: 'Logs',
        NEWS: 'News',
        TESTS: 'Tests',
        COVID: 'Covid',
        KIOSK: 'Kiosk',
        ROLES: 'Roles',
        ITEMS: 'Items',
        USERS: 'Users',
        STATES: 'States',
        VIDEO_CATEGORY: 'Video Category',
        VIDEO: 'Video',
        MANAGE: 'Manage',
        SUMMARY: 'Summary',
        TICKETS: 'Tickets',
        MASTERS: 'Masters',
        CLINICS: 'Clinics',
        DOCTORS: 'Doctors',
        STOCK_IN: 'StockIn',
        SETTINGS: 'Settings',
        PATIENTS: 'Patients',
        LAB_TESTS: 'LabTests',
        STOCK_OUT: 'StockOut',
        EXPOSE_API: 'ExposeApi',
        COUNTRIES: 'Countries',
        DISTRICTS: 'Districts',
        CUSTOMERS: 'Customers',
        DASHBOARD: 'Dashboard',
        DIAGNOSIS: 'Diagnosis',
        INVENTORY: 'Inventory',
        LOCATIONS: 'Locations',
        MEDICINES: 'Medicines',
        QUESTIONS: 'Questions',
        TEST_TYPES: 'TestTypes',
        MONITORING: 'Monitoring',
        CATEGORIES: 'Categories',
        TREATMENTS: 'Treatments',
        PERMISSIONS: 'Permissions',
        CLINIC_TYPES: 'ClinicTypes',
        TICKET_TYPES: 'TicketTypes',
        TELEMEDICINE: 'TeleMedicine',
        OBSERVATIONS: 'Observations',
        STOCK_REQUEST: 'StockRequest',
        CONFIGURATION: 'Configuration',
        COVID_SYMPTOMS: 'CovidSymptoms',
        NEWS_CATEGORIES: 'NewsCategories',
        TEST_CATEGORIES: 'TestCategories',
        CUSTOMER_CLINICS: 'CustomerClinics',
        LETTER_HEAD_TYPES: 'LetterHeadTypes',
        MEDICAL_COMPLAINTS: 'MedicalComplaints',
        VIDEO_CONSULTATION: 'VideoConsultation',
        NOTIFICATION_CONFIG: 'NotificationConfig',
        DEVICE_MANUFACTURERS: 'DeviceManufacturers',
        KIOSK_CONFIGURATIONS: 'KioskConfigurations',
        EXTERNAL_INTEGRATIONS: 'ExternalIntegrations',
        MEDICAL_COMPLICATIONS: 'MedicalComplications'
    };

    STOCK_TYPES = {
        IN: 'In',
        OUT: 'Out',
        REQUEST: 'Request'
    };

    STOCK_STEPS = {
        NEW: 'new',
        SHIPPED: 'shipped',
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
        COMPLETED: 'completed'
    };

    CUSTOMER_DOMAINS = [{
        'name': 'hpe',
        'logo': 'hpe.jpg'
    }, {
        'name': 'app',
        'logo': 'hll.png'
    }];
}