import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpInterceptor
} from '@angular/common/http'
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

    constructor(
        private sessionService: SessionService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string = this.sessionService.getToken();

        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`),
            });
        }

        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                    }
                }, error => {
                    if (error) {
                        //this.sessionService.clearSessionData();
                        //window.location.href = '/login';
                    }
                })
            )
    }
}