import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    isLogged: boolean = false;

    constructor(
        @Inject(SESSION_STORAGE) private sessionStorage: StorageService
    ) {
    }

    /*
     *   Store logo
     */
    setLogo(logo) {
        this.sessionStorage.set('logo', logo);
    }

    /*
     *   Get logo
     */
    getLogo() {
        return this.sessionStorage.get('logo');
    }

    /*
     *   Store permissions
     */
    setPermissions(permissions) {
        this.sessionStorage.set('permissions', JSON.stringify(permissions));
    }

    /*
     *   Get permissions
     */
    getPermissions() {
        return this.sessionStorage.get('permissions');
    }

    /*
     *   Store token
     */
    setToken(token) {
        this.sessionStorage.set('token', token);
    }

    /*
     *   Get token
     */
    getToken() {
        return this.sessionStorage.get('token');
    }

    /*
     *   Get stored settings
     */
    getSetting() {
        return this.sessionStorage.get('setting');
    }

    /*
     *   Store settings
     */
    setSetting(setting) {
        this.sessionStorage.set('setting', setting);
    }

    clearSessionData() {
        this.sessionStorage.set('user', null);
        this.sessionStorage.set('token', null);
    }

    /*
     *   Set logged user
     */
    setLoggedUser(user: any) {
        this.sessionStorage.set('user', user);
    }

    /*
     *   Get logged user
     */
    getLoggedUser() {
        return this.sessionStorage.get('user');
    }

    /*
     *  Set filter items
     */
    setFilter(filterData: any) {
        this.sessionStorage.set('filterData', filterData);
    }

    /*
     *  Get filter items
     */
    getFilter() {
        return this.sessionStorage.get('filterData');
    }

    clearFilter() {
        this.sessionStorage.remove('filterData');
    }

    /*
     *   Return logged status
     */
    logout() {
        this.clearSessionData();

        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }
}
