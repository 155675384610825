import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";

import { HttpService } from './http.service';
import { DateService } from './date.service';
import { ConstantsService } from './constants.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    protected url: string;
    private ds: DateService;
    private http: HttpService;
    private constants: ConstantsService;

    constructor() { }

    setup(
        baseURL: string,
        httpService: HttpService,
        dateService: DateService,
        constantsService: ConstantsService
    ) {
        this.url = baseURL;
        this.ds = dateService;
        this.http = httpService;
        this.constants = constantsService;
    }

    /*
     *  Get single record by id
     */
    public read(data): Observable<any> {
        let requestObject = {
            API_URL: this.url + data.id,
            REQUEST_METHOD: this.constants.REQUEST_METHOD_GET
        };

        return this.http.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Get filtered records
     */
    public filter(data): Observable<any> {
        let requestObject = {
            BODY: data,
            API_URL: this.url + 'filter',
            REQUEST_METHOD: this.constants.REQUEST_METHOD_POST
        };

        return this.http.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Save new record
     */
    public save(data): Observable<any> {
        data['operation'] = this.constants.OPERATION_TYPE_CREATE;
        data['created_at'] = this.ds.getCurrentDateTimeString();

        let requestObject = {
            BODY: data,
            API_URL: this.url,
            REQUEST_METHOD: this.constants.REQUEST_METHOD_POST
        };

        return this.http.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Update record
     */
    public update(data): Observable<any> {
        data['operation'] = this.constants.OPERATION_TYPE_UPDATE;
        data['updated_at'] = this.ds.getCurrentDateTimeString();

        let requestObject = {
            BODY: data,
            API_URL: this.url + data.id,
            REQUEST_METHOD: this.constants.REQUEST_METHOD_PUT
        };

        return this.http.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Remove record
     */
    public remove(data): Observable<any> {
        let requestObject = {
            API_URL: this.url + data.id,
            REQUEST_METHOD: this.constants.REQUEST_METHOD_DELETE
        };

        return this.http.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Extract data from response
     */
    public extractData(res: Response) {
        let body = res;
        return body || {};
    }
}