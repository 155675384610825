import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';

import { DoctorService } from 'src/app/services/doctor.service';

@Component({
    selector: 'app-doctor-profile',
    templateUrl: './doctor-public-profile.component.html',
    styleUrls: ['./doctor-public-profile.component.css']
})
export class DoctorPublicProfileComponent implements OnInit {

    user_guid: string;

    doctor: any;
    doctorName: string;
    doctorImage: string;
    doctorEducations: string;
    doctorSpecializations: string;

    doctorServicesFound: boolean = false;
    doctorEducationsFound: boolean = false;
    doctorAchievementsFound: boolean = false;
    doctorSpecializationsFound: boolean = false;
    doctorHospitalAssociationsFound: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private doctorService: DoctorService,
        private constantsService: ConstantsService,
    ) { }

    ngOnInit() {
        let that = this;

        this.route.params.subscribe(params => {
            that.user_guid = params['user_guid']; // (+) converts string 'id' to a number
            that.readDoctor();
        });
    }

    readDoctor() {
        let that = this;
        let data: any = {
            id: 'profile/' + this.user_guid
        };

        return new Promise((resolve, reject) => {
            this.doctorService.read(data).subscribe((response: any) => {
                if (response && response.data) {
                    that.doctor = response.data;

                    if (that.doctor.date_of_birth) {
                        that.doctor.date_of_birth = new Date(that.doctor.date_of_birth);
                    }

                    that.doctorName = that.doctor.first_name;
                    if (that.doctor.last_name)
                        that.doctorName += " " + that.doctor.last_name;

                    that.doctorServicesFound = that.doctor.doctorServices && that.doctor.doctorServices.length > 0;
                    that.doctorEducationsFound = that.doctor.doctorEducations && that.doctor.doctorEducations.length > 0;
                    that.doctorAchievementsFound = that.doctor.doctorAchievements && that.doctor.doctorAchievements.length > 0;
                    that.doctorSpecializationsFound = that.doctor.doctorSpecializations && that.doctor.doctorSpecializations.length > 0;
                    that.doctorHospitalAssociationsFound = that.doctor.doctorHospitalAssociations && that.doctor.doctorHospitalAssociations.length > 0;

                    if (that.doctor.image_file) {
                        that.doctorImage = that.constantsService.SERVER_ROOT_URL + that.constantsService.API_VERSION_V3 + that.doctor.image_file;

                    } else {
                        that.doctorImage = "/assets/images/users/d1.jpg";
                    }

                    if (that.doctorEducationsFound) {
                        that.doctorEducations = '';
                        if (that.doctor.doctorEducations && that.doctor.doctorEducations.length > 0) {
                            let arr: string[] = [];

                            that.doctor.doctorEducations.forEach((education: any) => {
                                arr.push(education.title);
                            });

                            that.doctorEducations = arr.join(', ');
                        }
                    }
                } else
                    that.doctor = null;

                resolve(null);
            });
        });
    }

}
