import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { MatTableExporterModule } from "mat-table-exporter";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatDividerModule } from "@angular/material/divider";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { MatCardModule } from "@angular/material/card";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

/************************* Components ****************************/

import { AppComponent } from "./app.component";
import { PublicLayoutComponent } from "./components/layout/public-layout.component";

//#region "Components"

import { ParameterDetailsComponent } from "./modules/patient/components/parameter/parameter-details.component";
import { DoctorPublicProfileComponent } from "./modules/doctor/components/doctor-public-profile/doctor-public-profile.component";

/*********** Static Components **********/

import { LoginComponent } from "./components/public/login/login.component";
import { IndexComponent } from "./components/public/index/index.component";

//#endregion

/************************* Services ****************************/

//#region "Services"

import { InterceptService } from "./services/intercept.service";
import { DisclaimerComponent } from './components/public/disclaimer/disclaimer.component';

//#endregion

/**
 * Route constant
 */
const routes: Routes = [
    { path: "", component: LoginComponent, pathMatch: "full" },
    {
        path: "", component: PublicLayoutComponent, children: [
            { path: 'login', component: LoginComponent }
        ]
    },
    { path: 'disclaimer', component: DisclaimerComponent },
    { path: 'doctor/profile/:user_guid', component: DoctorPublicProfileComponent },
    { path: 'dashboard', loadChildren: () => import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule) },
    { path: 'doctor-dashboard', loadChildren: () => import('./modules/doctor-dashboard/doctor-dashboard.module').then(m => m.DoctorDashboardModule) }
];

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        LoginComponent,
        DisclaimerComponent,
        PublicLayoutComponent,
        ParameterDetailsComponent,
        DoctorPublicProfileComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        BrowserModule,
        MatCardModule,
        CKEditorModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        HttpClientModule,
        MatPaginatorModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatTableExporterModule,
        BrowserAnimationsModule,

        RouterModule.forRoot(routes),
    ],
    providers: [
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

//#endregion
