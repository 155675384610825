import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { ConstantsService } from './constants.service';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    basePath: string;

    isEmptyObject(obj: any): boolean {
        return (obj && (Object.keys(obj).length === 0));
    }

    constructor(
        private sessionService: SessionService,
        public constantsService: ConstantsService
    ) {
        this.basePath = this.constantsService.ASSET_BASE_PATH;
    }

    /* Return current page type */
    /**
     * Return current page type
     * @param {OBJECT} $routeParams
     * @param {OBJECT} $location
     */
    getPageType(currentUrl) {
        if (currentUrl.indexOf("/view") > -1) {
            return this.constantsService.PAGE_TYPE_VIEW;
        } else if (currentUrl.indexOf("/edit") > -1) {
            return this.constantsService.PAGE_TYPE_EDIT;
        } else if (currentUrl.indexOf("/create") > -1) {
            return this.constantsService.PAGE_TYPE_CREATE;
        } else if (currentUrl.indexOf("/list") > -1) {
            return this.constantsService.PAGE_TYPE_LIST;
        } else if (currentUrl.indexOf("/profile") > -1) {
            return this.constantsService.PAGE_TYPE_PROFILE;
        } else if (currentUrl.indexOf("/patient-detail") > -1) {
        }
    }

    /**
     * Return full name of person
     * @param {OBJECT} person
     */
    getFullName(person) {
        if (person) {
            let name = '';

            if (person.first_name && person.first_name.trim().length > 0)
                name = person.first_name.trim();

            if (person.middle_name && person.middle_name.trim().length > 0)
                name += ' ' + person.middle_name;

            if (person.last_name && person.last_name.trim().length > 0)
                name += ' ' + person.last_name;

            return name;
        } else {
            return null;
        }
    }

    /**
     * Trim file name if longer than 8 characters
     * @param {STRING} filename
     * @param {INTEGER} length
     */
    trimFileName(filename, length) {
        if (filename && filename.length > 10) {
            return filename.substring(0, length) + '..';
        } else {
            return filename;
        }
    }

    /**
     * Remove meta data from picture
     * @param {STRING} picture
     */
    stripImageBase64Meta(picture) {
        return picture.split(',')[1];
    }

    /**
     * Returns formatted address
     * @param {OBJECT} address
     */
    getFormattedAddress(address) {
        let formattedAddress = '';

        if (address.city && address.city.length > 0) {
            formattedAddress += address.city;
        } else {
            formattedAddress += 'No city';
        }

        if (address.state && address.state.length > 0) {
            formattedAddress += ', ' + address.state;
        } else {
            formattedAddress += ', No state';
        }

        formattedAddress += '<br/>' + address.Country.name;

        return formattedAddress;
    }

    /**
     * Return identification documents from passed array
     * @param {ARRAY} documents
     */
    getPersonIdentifications(documents) {
        let identifications = [];

        if (documents) {
            documents.forEach((document, index) => {
                if (document.DocumentType.name == this.constantsService.DOCUMENT_TYPE_ID_NAME) {
                    identifications.push(document);
                }
            });
        }

        return identifications;
    }

    /**
     * Get profile picture if exists or default
     * @param {OBJECT} Image 
     * @param {STRING} User, Doctor or Patient 
     */
    getProfilePicture(image, personType) {
        if (image) {
            let path = this.constantsService.SERVER_ROOT_URL + this.constantsService.API_VERSION_V3 + this.constantsService.API_URL_CDN;

            if (personType == this.constantsService.PROFILE_PICTURE_DOCTOR) {
                path += 'images/doctor/';
            } else if (personType == this.constantsService.PROFILE_PICTURE_PATIENT) {
                path += 'images/patient/';
            } else if (personType == this.constantsService.PROFILE_PICTURE_USER) {
                path += 'images/user/';
            } else if (personType == this.constantsService.PROFILE_PICTURE_REFERRAL_PARTNER) {
                path += 'images/user/';
            }

            return path + image.saved_file_name;
        } else {
            if (personType == this.constantsService.PROFILE_PICTURE_PATIENT) {
                return this.basePath + this.constantsService.DEFAULTS_PATIENT_PICTURE;
            } else if (personType == this.constantsService.PROFILE_PICTURE_USER) {
                return this.basePath + this.constantsService.DEFAULTS_USER_PICTURE;
            }
        }
    }

    /**
     * Find speciality object from passed specailities and speciality id
     * @param {ARRAY} specialities 
     * @param {INTEGER} speciality_id 
     */
    getSpecialityById(specialities, speciality_id) {
        let speciality = null;

        if (specialities && specialities.length > 0) {
            specialities.forEach(specialityObject => {
                if (specialityObject.id == speciality_id) {
                    speciality = specialityObject;
                }
            });
        }

        return speciality;
    }

    /**
     * Find and return role of given type from passed roles
     * @param {ARRAY} roles 
     * @param {STRING} Role roleType 
     */
    getRole(roles, roleType) {
        let foundRole = null;

        roles.forEach((role, index) => {
            if (roleType == role.name) {
                foundRole = role;
            }
        });

        return foundRole;
    }

    /**
     * Displays updating loader
     * @param {OBJECT} scope 
     */
    showUpdating(scope) {
        scope.updating = true;
    }

    /**
     * Hides updating loader
     * @param {OBJECT} scope 
     */
    hideUpdating(scope) {
        scope.updating = false;
    }

    /**
     * Receive an array of patient visits, return active visit
     * @param {ARRAY} patientVisits 
     */
    getCurrentPatientVisit(patientVisits) {
        let patientCurrentVisit = null;

        patientVisits.forEach((visit, index) => {
            if (visit.is_active) {
                patientCurrentVisit = visit;
            }
        });

        return patientCurrentVisit;
    }

    isCustomerLogged() {
        return this.sessionService.getLoggedUser().user_type == this.constantsService.USER_TYPE_CUSTOMER;
    }

    /*
     *  Prepares permissions according to modules
     */
    preparePermissions(permissions: any) {
        let preparedPermissions = {};
        if (permissions) {
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i] && permissions[i].module) {
                    preparedPermissions[permissions[i].module.toLowerCase()] = permissions[i];
                }
            }
        }

        return preparedPermissions;
    }

    isAdministratorLogged() {
        if (this.sessionService.getLoggedUser() && this.sessionService.getLoggedUser().name) {
            return this.sessionService.getLoggedUser().role.name.toUpperCase() == this.constantsService.ROLE_ADMINISTRATOR.toUpperCase();
        } else {
            return false;
        }
    }

    //////////////////// Functions to get read, write, remove permissions of a module ////////////////////

    /*
     *  Check if module has read permission
     */
    canRead(module: string) {
        let permissionsString = this.sessionService.getPermissions();

        if (permissionsString) {
            let permissions = JSON.parse(permissionsString);
            return permissions && permissions[module.toLowerCase()] ? permissions[module.toLowerCase()].read : false;
        } else {
            return false;
        }
    }

    /*
     *  Check if module has write permission
     */
    canWrite(module: string) {
        let permissionsString = this.sessionService.getPermissions();
        if (permissionsString) {
            let permissions = JSON.parse(permissionsString);
            return permissions && permissions[module.toLowerCase()] ? permissions[module.toLowerCase()].write : false;
        } else {
            return false;
        }
    }

    /*
     *  Check if module has remove permission
     */
    canRemove(module: string) {
        let permissionsString = this.sessionService.getPermissions();
        if (permissionsString) {
            let permissions = JSON.parse(permissionsString);
            return permissions && permissions[module.toLowerCase()] ? permissions[module.toLowerCase()].remove : false;
        } else {
            return false;
        }
    }

    /**
     * getChartColor
     * @param label 
     */
    getChartBackgroundColor(label) {
        switch (label) {
            case 'Male':
                return this.constantsService.COLORS.MALE;
            case 'Female':
                return this.constantsService.COLORS.FEMALE;
            case 'SERIOUSLY LOW':
                return this.constantsService.COLORS.SERIOUSLY_HIGH;
            case 'LOW':
                return this.constantsService.COLORS.LOW;
            case 'STANDARD':
                return this.constantsService.COLORS.STANDARD;
            case 'ADEQUATE':
                return this.constantsService.COLORS.ADEQUATE;
            case 'HIGH':
                return this.constantsService.COLORS.HIGH;
            case 'VERY HIGH':
                return this.constantsService.COLORS.VERY_HIGH;
            case 'BORDER LINE':
                return this.constantsService.COLORS.BORDER_HIGH;
            case 'SERIOUSLY HIGH':
                return this.constantsService.COLORS.SERIOUSLY_HIGH;
            case 'NOT UP TO STANDARD':
                return this.constantsService.COLORS.NOT_UP_TO_STANDARD;
            case 'Symptomatic':
                return this.constantsService.COLORS.SYMPTOMATIC;
            case 'Asymptomatic':
                return this.constantsService.COLORS.ASYMPTOMATIC;
            case 'Positive':
                return this.constantsService.COLORS.POSITIVE;
            case 'Negative':
                return this.constantsService.COLORS.NEGATIVE;
            case 'DIABETIC':
                return this.constantsService.COLORS.DIABETIC;
            case 'NORMAL':
                return this.constantsService.COLORS.NORMAL;
            case 'PREDIABETIC':
                return this.constantsService.COLORS.PREDIABETIC;
            case 'DESIRABLE':
                return this.constantsService.COLORS.DESIRABLE;
            case 'OPTIMAL':
                return this.constantsService.COLORS.OPTIMAL;
            case 'NEAR OPTIMAL':
                return this.constantsService.COLORS.NEAR_OPTIMAL;
            case 'BORDER LINE HIGH':
                return this.constantsService.COLORS.BORDER_LINE_HIGH;
            case 'Likely to be positive':
                return this.constantsService.COLORS.POSITIVE;
            default:
                return this.constantsService.COLORS.DEFAULT;
        }
    }

    /**
     * Remove number padding
     */
    removeNumberPadding(x: number): number {
        if (("" + x).indexOf("0") == 0)
            return parseInt(("" + x).substring(1));
        else
            return x;
    }

    exportToExcel(data: any) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'data.xlsx';
        const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(file, fileName);
    }
}
