import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

import { UtilityService } from './utility.service';
import { SessionService } from './session.service';
import { ConstantsService } from './constants.service';
import { catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private httpClient: HttpClient,
        public utilityService: UtilityService,
        private sessionService: SessionService,
        public constantsService: ConstantsService
    ) { }

    sendRequest(requestData) {
        let httpOptions = {};

        let url = this.constantsService.SERVER_ROOT_URL + requestData.API_URL;

        let user = this.sessionService.getLoggedUser();

        /* All data is related to customer_id which is returned on login */
        if (user) {
            let requestHeaders: any = {
                loggedusertype: user.user_type,
                loggeduserid: user.id.toString(),
                'Content-Type': 'application/json'
            };

            if (requestData.kioskid && (requestData.kioskid != 'undefined') && requestData.kioskid != undefined)
                requestHeaders.clinicid = requestData.kioskid;

            httpOptions = {
                withCredentials: false,
                headers: new HttpHeaders(requestHeaders)
            };
        } else {
            httpOptions = {
                withCredentials: false,
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
        }

        requestData = this.sanitizeRequest(requestData);

        if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_GET) {
            return this.httpClient.get(url, httpOptions);
        } else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_POST) {
            return this.httpClient.post(url, requestData.BODY, httpOptions);
        } else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_PUT) {
            return this.httpClient.put(url, requestData.BODY, httpOptions);
        } else if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_DELETE) {
            return this.httpClient.delete(url, httpOptions);
        }
    }

    getFileRequest(requestData) {
        let httpOptions = {};

        let url = this.constantsService.SERVER_ROOT_URL + requestData.API_URL;

        let token = this.sessionService.getToken();
        let user = this.sessionService.getLoggedUser();

        /* All data is related to customer_id which is returned on login */
        if (token) {
            httpOptions = {
                responseType: 'blob',
                withCredentials: false,
                headers: new HttpHeaders({
                    authtoken: token,
                    loggedusertype: user.user_type,
                    loggeduserid: user.id.toString(),
                    'Content-Type': 'application/json'
                })
            };
        } else {
            httpOptions = {
                responseType: 'blob',
                withCredentials: false,
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
        }

        requestData = this.sanitizeRequest(requestData);

        if (requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_GET) {
            return this.httpClient.get(url, httpOptions);
        }
    }

    sendFileRequest(requestData: any) {
        let url = this.constantsService.SERVER_ROOT_URL + requestData.API_URL;

        const formData: FormData = new FormData();
        formData.append('file', requestData.FILE);
        formData.append('data', JSON.stringify(requestData.BODY));

        const request = new HttpRequest(requestData.REQUEST_METHOD, url, formData, {
            responseType: 'blob'
        });

        return this.httpClient.request(request);
    }

    sendFileV2Request(requestData: any) {
        let url = this.constantsService.SERVER_ROOT_URL + requestData.API_URL;

        const formData: FormData = new FormData();

        console.log(">>>>>> requestData.FILE", requestData.FILE);
        if (requestData.FILE)
            formData.append('file', requestData.FILE[0]);

        // Append each key-value pair from requestData.BODY to the formData
        for (const key in requestData.BODY) {
            if (requestData.BODY.hasOwnProperty(key)) {
                
                formData.append(key, requestData.BODY[key]);
            }
        }

        const request = new HttpRequest(requestData.REQUEST_METHOD, url, formData, {
            responseType: 'json'
        });

        return this.httpClient.request(request).pipe(
            map((event) => {
              if (event instanceof HttpResponse) {
                // Handle the full response
                return event.body;
              }
              return null;
            }),
            catchError((error: HttpErrorResponse) => {
              console.error('Error during file upload:', error);
              throw error; // Re-throw error after logging or handling
            })
          );;
    }
    // returns true if save or update call
    isDataUpdateOperation(requestData) {
        return requestData.BODY &&
            (
                requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_POST ||
                requestData.REQUEST_METHOD == this.constantsService.REQUEST_METHOD_PUT
            );
    }

    sanitizeRequest(requestData) {
        return requestData;
    }
}