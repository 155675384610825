import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Title } from '@angular/platform-browser';
import { ConstantsService } from './constants.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    baseURL: string = this.constantsService.API_VERSION_V3 + this.constantsService.API_URL_CHECK_LOGIN;

    constructor(
        private titleService: Title,
        private httpService: HttpService,
        public constantsService: ConstantsService,
    ) {
        this.titleService.setTitle("Clinics On Cloud Authentication");
    }

    /*
    *  Check login
    */
    checkLogin(data): Observable<any> {
        let requestObject = {
            BODY: data,
            API_URL: this.baseURL,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_POST
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
    *  Extract data from response
    */
    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
}