var moment = require('moment');
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    /**
     * Converts minutes to hour string
     * @param {INTEGER} minutes 
     */
    getHoursFormatFromMinutes(minutesPassed) {
        let minutes = Math.abs(minutesPassed);

        let hours = (minutes / 60);
        let roundedHours = Math.floor(hours);
        minutes = (hours - roundedHours) * 60;
        minutes = Math.round(minutes);

        let result = this.pad(roundedHours) + ":" + this.pad(minutes);

        return minutesPassed > 0 ? "+" + result : "-" + result;
    }

    /**
     * Adds a 0 on left for single digit number
     * @param {INTEGER} number 
     */
    pad(x: number) {
        if (x == 0) {
            return "00";
        } else if (x > 9) {
            return x;
        } else {
            return "0" + x;
        }
    }

    /**
     * Returns current UTC time as string
     */
    getCurrentDateTimeString() {
        let now = moment();
        return now.format();
    }

    getDatabaseFormatDateTime(date: Date): any {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let strDate = this.pad(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear();
        let strTime = this.pad(date.getHours() >= 12 ? (date.getHours() - 12) : date.getHours()) + ':' + this.pad(date.getMinutes()) + ' ' + (date.getHours() >= 12 ? 'pm' : 'am');

        return strDate + ' ' + strTime;
    }

    getDatabaseFormatDateString(date: Date): any {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        return this.pad(date.getDate()) + '-' + months[date.getMonth()] + '-' + date.getFullYear();
    }

    getRequestFormatDateString(date: Date): any {
        return this.pad(date.getFullYear()) + '-' + this.pad(date.getMonth() + 1) + '-' + this.pad(date.getDate());
    }

    getFormattedDateString(date: string): any {
        // date is in the format: 1999-12-31T18:30:00.000Z
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let temp = date.split('T');

        let arDate = temp[0].split('-');

        return arDate[2] + '-' + months[parseInt(arDate[1]) - 1] + '-' + arDate[0];
    }

    getFormattedTimeString(date: string): any {
        // date is in the format: 1999-12-31T18:30:00.000Z
        let temp = date.split('T');

        let arTime = temp[1].split(':');

        let hour = parseInt(arTime[0]);

        let formattedTime = this.pad(hour >= 12 ? (hour - 12) : hour) + ':' + this.pad(parseInt(arTime[1])) + ' ' + (hour >= 12 ? 'pm' : 'am');
        return formattedTime;
    }

    getFormattedDateTimeString(date: string): any {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // date is in the format: 1999-12-31T18:30:00.000Z
        let temp = date.split('T');

        let arDate = temp[0].split('-');
        let arTime = temp[1].split(':');

        let hour = parseInt(arTime[0]);

        return arDate[2] + '-' + months[parseInt(arDate[1]) - 1] + '-' + arDate[0] + ' ' + this.pad(hour >= 12 ? (hour - 12) : hour) + ':' + this.pad(parseInt(arTime[1])) + ' ' + (hour >= 12 ? 'pm' : 'am');
    }

    isFutureDate(date: Date) {
        let now = new Date();

        return date > now;
    }

    getStartOfCurrentMonth() {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    getCurrentDate() {
        return new Date();
    }
}