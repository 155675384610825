<section class="login-page">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-6">
                <div class="login-info">
                    <div class="login-logo">
                        <img src="{{logo}}" alt="{{logoAlt}}" *ngIf="!clientLogo" />
                    </div>
                    <div class="login-image">
                        <img src="/assets/images/theme/illustration.svg" alt="{{logoAlt}}" />
                    </div>
                    <div class="login-tagline">
                        <h1>Empowering Society for a Healthier TOMORROW</h1>
                        <p>Making preventive healthcare more Affordable, Advanced and Accessible</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="login-form">
                    <div class="client-logo">
                        <img src="{{logo}}" alt="{{logoAlt}}" *ngIf="clientLogo" />
                    </div>
                    <h2>Sign In</h2>
                    <form name="form" (ngSubmit)="f.form.valid && checkLogin()" #f="ngForm" novalidate>
                        <div class="form-group">
                            <input type="text" name="username" [(ngModel)]="username" #username_v="ngModel"
                                class="form-control input-lg text-center" placeholder="Enter username" required />
                            <div *ngIf="username_v.invalid && (formSubmitted || username_v.dirty || username_v.touched)"
                                class="alert alert-danger">
                                <div [hidden]="!username_v.errors.required">
                                    Username is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="password" name="password" [(ngModel)]="password" #password_v="ngModel"
                                class="form-control input-lg text-center" placeholder="Enter password" required />
                            <div *ngIf="password_v.errors && (password_v.dirty || password_v.touched)"
                                class="alert alert-danger">
                                <div [hidden]="!password_v.errors.required">
                                    Password is required
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-block btn-theme-primary">Login</button>
                        <br /><br />
                        <span>{{message}}</span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>