import { Component, OnInit } from '@angular/core';

import { LoginService } from 'src/app/services/login.service';
import { SessionService } from 'src/app/services/session.service';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    /*************************** Variables ***********************/

    logo: string = '';
    message: string = '';
    password: string = '';
    username: string = '';
    logoAlt: string = 'Clinics on Cloud';
    logoUrl: string = '/assets/images/theme/';

    updating: boolean = false;
    clientLogo: boolean = false;
    formSubmitted: boolean = false;

    /*************************** Variables ***********************/

    constructor(
        private loginService: LoginService,
        private sessionService: SessionService,
        private constantsService: ConstantsService
    ) {
        let url: string = window.location.hostname;
        this.setLogo(url);
    }

    /**
     * @function ngOnInit
     * @description Called when component is initialized
     */
    ngOnInit() {
    }

    setLogo(url: string) {
        this.logo = this.logoUrl + 'coc-logo.png';

        let arUrlParts = url.split('.');
        let subDomain = arUrlParts[0];

        this.constantsService.CUSTOMER_DOMAINS.forEach((domain: any) => {
            if (domain.name.toLowerCase() == subDomain.toLowerCase()) {
                this.clientLogo = true;
                this.logo = this.logoUrl + domain.logo;
            }
        });

        this.sessionService.setLogo(this.logo);
    }

    checkLogin() {
        let that = this;
        that.message = '';

        this.formSubmitted = true;

        let data = {
            username: this.username,
            password: this.password
        };

        this.loginService.checkLogin(data).subscribe((response: any) => {
            if (response.data.valid) {
                that.sessionService.setToken(response.data.token);

                /* Set data for logger user */
                that.sessionService.setLoggedUser(response.data.user);

                if (this.constantsService.USER_TYPE_DOCTOR == response.data.user.user_type)
                    window.location.href = 'doctor-dashboard/data';
                else
                    window.location.href = 'dashboard/dashboard-home';
            } else {
                that.message = response.data.message;
            }
        });
    }
}
