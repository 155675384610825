import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { DateService } from './date.service';
import { HttpService } from './http.service';
import { ConstantsService } from './constants.service';

@Injectable({
    providedIn: 'root'
})
export class DoctorService extends BaseService {

    baseURL: string = this.constantsService.API_VERSION_V3 + this.constantsService.API_URL_DOCTOR;

    constructor(private constantsService: ConstantsService, private httpService: HttpService, private dateService: DateService) {
        super();

        this.setup(
            this.baseURL,
            this.httpService,
            this.dateService,
            this.constantsService
        );
    }

    /*
     *  Save new doctor
     */
    saveDoctor(data) {
        data['operation'] = this.constantsService.OPERATION_TYPE_CREATE;

        var requestObject = {
            BODY: data,
            API_URL: this.baseURL + 'doctor',
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_POST
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Update record
     */
    updatePassword(data): Observable<any> {
        data['operation'] = this.constantsService.OPERATION_TYPE_UPDATE;

        let requestObject = {
            BODY: data,
            API_URL: this.baseURL + 'password/' + data.id,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_PUT
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Upload user images
     */
    uploadImages(data) {
        data['operation'] = this.constantsService.OPERATION_TYPE_CREATE;

        let requestObject = {
            BODY: data,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_POST,
            API_URL: this.baseURL + this.constantsService.API_URL_USER_IMAGE
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Remove user image
     */
    removeImage(data) {
        let requestObject = {
            BODY: '',
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_DELETE,
            API_URL: this.baseURL + this.constantsService.API_URL_USER_IMAGE + data.id
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Update user image
     */
    updateImage(data) {
        data['operation'] = this.constantsService.OPERATION_TYPE_CREATE;

        let requestObject = {
            BODY: data,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_POST,
            API_URL: this.constantsService.API_VERSION_V3 + this.constantsService.API_URL_USER_IMAGE
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Load user images
     */
    loadProfilePicture(data) {
        let requestObject = {
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_GET,
            API_URL: this.constantsService.API_VERSION_V3 + this.constantsService.API_URL_USER_IMAGE + data.id
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Load user images
     */
    readLetterHead(data) {
        let requestObject = {
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_GET,
            API_URL: this.baseURL + this.constantsService.API_URL_LETTERHEAD + data.id
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *   Load user images
     */
    updateLetterHead(data: any, id: number) {
        data['operation'] = this.constantsService.OPERATION_TYPE_UPDATE;

        let requestObject = {
            BODY: data,
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_PUT,
            API_URL: this.baseURL + this.constantsService.API_URL_LETTERHEAD + id
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }

    /*
     *  Get all records by page
     */
    doctorsWithAssignment(data): Observable<any> {
        let requestObject = {
            BODY: data,
            API_URL: this.baseURL + 'read-with-assignments',
            REQUEST_METHOD: this.constantsService.REQUEST_METHOD_POST
        };

        return this.httpService.sendRequest(requestObject).pipe(map(this.extractData));
    }
}