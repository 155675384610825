<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="my-5 position-rel text-center">
                <h1 class=" heading">terms and conditions</h1>
                <span class="position-abso"
                    style="border-bottom: 4px solid #d83452; width: 5rem; display: inline-block;"></span>
            </div>
            <div>
                <div>
                    <h6>I have understood & consent that,</h6>
                </div>
                <ol class="list">
                    <li class="list-item">
                        The Health Kiosk uses CE/US FDA approved medical grade devices
                        to conduct preventive health tests.
                    </li>
                    <li class="list-item">
                        This report is not intended to replace but to lead by providing
                        comprehensive information. It is recommended that you consult
                        your doctor/physician for interpretation of results.
                    </li>
                    <li class="list-item">
                        All reports might not be applicable for individuals less than
                        18, pregnant women or individuals suffering from diseases for
                        which health test has not been performed or symptoms not
                        diagnosed.
                    </li>
                    <li class="list-item">
                        This report is based on preventive health test screening and is
                        meant for a healthy lifestyle. It does not provide any
                        recommendation for life threatening situations.
                    </li>
                    <li class="list-item">
                        It is strongly recommended to take required precautions for
                        allergic reactions or sensitivities.
                    </li>
                    <li class="list-item">
                        The content is provided for information purposes only in
                        believed to be serving as a preventive health screening and is
                        not intended as, and should not be considered to a legal or
                        financial advice.
                    </li>
                    <li class="list-item">
                        References and links to third parties do not constitute any
                        endorsements or warranty by this report generated at the Health
                        Kiosk. The health ATM hereby disclaim all express and implied
                        warranties of any kind.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>